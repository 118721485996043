import { NgModule } from "@angular/core";
import { LocalizationTranslations, Localization } from "./localization.service";

@NgModule({
    imports: [
    ],
    providers: [
        LocalizationTranslations,
        Localization,
    ],
    exports: [
    ]
})
export class SharedServicesModule {}